import { InfoCardInner } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './CompanySettings.module.scss';
import clsx from 'clsx';
import { TPrivateCompanyFieldsFragment } from '../../../generated/graphql';
import { usePrivateCompanyContext } from '../../../routes/outlets/PrivateCompanyOutlet';

export const CompanySettings = () => {
  const company = usePrivateCompanyContext();

  return (
    <div className={clsx(styles.companyAccount, 'grid grid-cols-12')}>
      <div className='col-span-8 col-start-3'>
        <InfoCardInner<TPrivateCompanyFieldsFragment>
          data={company}
          sections={[
            {
              title: 'Company Setup',
              columns: [
                {
                  label: 'ID',
                  accessor: 'id',
                },
                {
                  label: 'Start Date',
                  accessor: 'startDate',
                },
                {
                  label: 'Chocolate Soup Manager',
                  accessor: 'accountManager',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Plan Includes',
                  render: () => {
                    return (
                      <ul>
                        {company.employeeBirthdayActivated && <li>Employees Birthdays</li>}
                        {company.employeeWorkAnniversaryActivated && <li>Employees Work Anniversaries</li>}
                        {company.employeeNewHireKitActivated && <li>Employees New Hire Kits</li>}
                        {company.significantOtherBirthdayActivated && <li>Significant Others Birthdays</li>}
                        {company.childBirthdayActivated && <li>Children Birthdays</li>}
                        {company.petBirthdayActivated && <li>Pets Birthdays</li>}
                      </ul>
                    );
                  },
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Email Domains',
                  render: () => {
                    return (
                      <ul>
                        {company.emailDomains.map((email, index) => {
                          return <li key={index}>{email}</li>;
                        })}
                      </ul>
                    );
                  },
                },
              ],
            },
          ]}
        />
        <InfoCardInner<TPrivateCompanyFieldsFragment>
          data={company}
          sections={[
            {
              title: 'Gift Preferences',
              columns: [
                {
                  label: 'Shipping to',
                  accessor: 'deliveryMethod',
                  render: (data) => {
                    switch (data?.deliveryMethod) {
                      case 'home':
                        return "Employee's Home";
                      case 'office':
                        return "Employee's Office";
                      case 'hybrid':
                        return "Employee's Home or Office";
                      default:
                        return 'N/A';
                    }
                  },
                },
                {
                  label: 'Special notes',
                  accessor: 'specialNote',
                },
                {
                  label: 'Sign as',
                  accessor: 'signAs',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Swag',
                  accessor: 'swag',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Lego Activated',
                  accessor: 'legoActivated',
                },
                {
                  label: 'Custom legop',
                  accessor: 'customLego',
                },
                {
                  label: 'Lego Color',
                  accessor: 'legoColor',
                },
                {
                  label: 'Tissue Color',
                  accessor: 'tissueColor',
                },
              ],
            },
          ]}
        />
        <InfoCardInner<TPrivateCompanyFieldsFragment>
          data={company}
          sections={[
            {
              title: 'Contact Information',
              columns: [
                {
                  label: 'Name',
                  render: (data) => data?.contact?.name,
                },
                {
                  label: 'Email',
                  render: (data) => data?.contact?.email,
                },
                {
                  label: 'Phone Number',
                  render: (data) => data?.contact?.phoneNumber,
                },
              ],
            },
          ]}
        />
        <InfoCardInner<TPrivateCompanyFieldsFragment>
          data={company}
          sections={[
            {
              title: 'Address',
              columns: [
                {
                  label: 'Address line 1',
                  render: (data) => data?.address?.address1,
                },
                {
                  label: 'Address line 2',
                  render: (data) => data?.address?.address2,
                },
                {
                  label: 'City',
                  render: (data) => data?.address?.city,
                },
                {
                  label: 'State',
                  render: (data) => data?.address?.state,
                },
                {
                  label: 'Country',
                  render: (data) => data?.address?.country,
                },
                {
                  label: 'Zip Code',
                  render: (data) => data?.address?.zipCode,
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};
